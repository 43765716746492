
import { defineComponent } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import { mapGetters } from "vuex";
import dayjs from "dayjs";

import { OperItem } from "@/types/state/production";

export default defineComponent({
  components: {
    Dialog,
    InputText,
    InputNumber,
    Calendar,
    Dropdown,
    Button,
  },
  emits: ["closeModal", "saveWorkOrder"],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    selectedOrder: {
      type: Object,
      required: true,
    },
    workCenterList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
      orderToEdit: {} as any,
      workCenter: "",
    };
  },
  computed: {
    ...mapGetters({
      getWorkOrderStatusItems: "workorder/getWorkOrderStatusItems",
    }),
    statusList() {
      return Object.keys(this.getWorkOrderStatusItems).reduce(
        (acc: any, key: any) => {
          acc.push({
            id: key,
            label: (this.getWorkOrderStatusItems as any)[key],
          });
          return acc;
        },
        [],
      );
    },
  },
  watch: {
    open(newOpen) {
      this.isVisible = newOpen;
    },
    selectedOrder(newOrder) {
      this.orderToEdit = JSON.parse(JSON.stringify(newOrder));
      this.getWorkCenter();
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal", this.isVisible);
    },
    getWorkCenter() {
      if (this.orderToEdit.oper_items && this.orderToEdit.oper_items.length) {
        const orderWorkCenter = this.orderToEdit.oper_items.find(
          (item: OperItem) => !item.oper.includes("-"),
        );
        this.workCenter = orderWorkCenter ? orderWorkCenter.work_center : "";
      }
    },
    saveWorkOrder() {
      if (this.orderToEdit.wo_qty) {
        this.orderToEdit.wo_qty = this.orderToEdit.wo_qty.toString();
        this.orderToEdit.req_date = dayjs(this.orderToEdit.req_date).format(
          "MM-DD-YYYY",
        );
        this.orderToEdit.rel_date = dayjs(this.orderToEdit.rel_date).format(
          "MM-DD-YYYY",
        );
      }
      this.$emit("saveWorkOrder", this.orderToEdit, this.workCenter);
    },
  },
});
