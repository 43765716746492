
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";
import MessageBox from "../MessageBox.vue";
import WorkOrderTable from "./WorkOrderTable.vue";
import Utils from "@/utility/utils";
import dayjs from "dayjs";
import { WorkOrder } from "@/types/workOrder";
import { WorkCenter } from "@/types/workCenter";

import WorkCenterService from "@/services/WorkCenterService";
const workCenterService = new WorkCenterService(
  process.env.VUE_APP_ABSTRACTION_API,
);

export default defineComponent({
  components: {
    Button,
    TabView,
    TabPanel,
    WorkOrderTable,
    MessageBox,
  },
  data() {
    return {
      tabToRemove: null,
      showConfirmDeleteTab: false,
      loadingSaveWorkOrder: false,
      active: 0,
    };
  },
  async created() {
    await this.fetchWorkCenters();
    if (this.getActiveTab > 0) {
      this.$router.push(
        `/production/work-order/${this.getActiveWorkOrder.oldRecord.wo_id}`,
      );
    }
  },
  computed: {
    ...mapGetters({
      getOpenedWorkOrders: "workorder/getOpenedWorkOrders",
      getActiveTab: "workorder/getActiveTab",
      getActiveWorkOrder: "workorder/getActiveWorkOrder",
      getWorkCenterItems: "workCenters/getWorkCenterItems",
    }),
  },
  methods: {
    ...mapActions({
      addWorkOrderToOpenedOrders: "workorder/addWorkOrderToOpenedOrders",
      removeOpenedOrderTab: "workorder/removeOpenedOrderTab",
      changeActiveTab: "workorder/changeActiveTab",
      addNotification: "notification/add",
      putOrders: "workorder/putOrders",
      fetchWorkCenters: "workCenters/fetchWorkCenters",
    }),
    handleTabChange(event: any) {
      this.changeActiveTab(event.index ? event.index : 0);
      if (event.index > 0) {
        const id = this.getOpenedWorkOrders[event.index - 1].oldRecord.wo_id;
        this.$router.push(`/production/work-order/${id}`);
      } else {
        this.$router.push(`/production/work-order/`);
      }
    },
    onOpenWorkOrder(data: any) {
      this.addWorkOrderToOpenedOrders(data);
      this.$router.push(`/production/work-order/${data.wo_id}`);
    },
    handleRemoveWorkOrderTab(tab: any) {
      const hasChanges = Utils.compareTwoObjects<WorkOrder>(
        tab.record,
        tab.oldRecord,
        [],
      );

      if (hasChanges) {
        this.tabToRemove = tab;
        this.showConfirmDeleteTab = true;
        return;
      }
      this.changeActiveTab(0);
      this.removeOpenedOrderTab(tab);
      this.$router.push("/production/work-order/");
    },
    handleCloseSaveFieldServiceTab() {
      if (this.tabToRemove) {
        this.removeOpenedOrderTab(this.tabToRemove);
      }
      this.showConfirmDeleteTab = false;
      this.$router.push("/production/work-order/");
    },
    handleCancelSaveFieldServiceTab() {
      this.showConfirmDeleteTab = false;
    },
    saveWorkOrder() {
      const recordToSave = this.getActiveWorkOrder.record;

      if (recordToSave.wo_qty) {
        recordToSave.wo_qty = recordToSave.wo_qty.toString();
      }
      recordToSave.req_date = dayjs(recordToSave.req_date).format("MM/DD/YYYY");
      recordToSave.rel_date = dayjs(recordToSave.rel_date).format("MM/DD/YYYY");

      this.loadingSaveWorkOrder = true;
      this.putOrders({
        oldRecords: [this.getActiveWorkOrder.oldRecord],
        records: [recordToSave],
      })
        .then(() => {
          this.removeOpenedOrderTab(this.getActiveWorkOrder);
          this.changeActiveTab(0);
          this.$router.push("/production/work-order/");
        })
        .finally(() => {
          this.loadingSaveWorkOrder = false;
        });
    },
    orderHasChanges(tab: { oldRecord: WorkOrder; record: WorkOrder }) {
      const hasChanges = Utils.compareTwoObjects<WorkOrder>(
        tab.oldRecord,
        tab.record,
        [],
      );
      return hasChanges ? " *" : "";
    },
  },
});
